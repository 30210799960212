import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import PrivateRoute, {isAuthenticated} from "./privateRoute";
import HomeCostPrize from "../Auth/homeCostPrize";
import HomeSellingPrize from "../Auth/homeSellingPrize";
import HomeOrders from "../Auth/homeOrders";
import HomeInvoice from "../Auth/homeInvoice";
import Clients from "../Auth/clients";
import Clientcredential from "../Auth/clientcredential";
import Clientinvoice from "../Auth/clientinvoice";
import Login from "../Auth/login";
import Dashboard from "../Auth/dashboard";
import HomeLaunch from "../Auth/homeLaunch";
import Landing from "../Auth/landing";
// import {} 
const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<PrivateRoute />}>
        </Route>
        {isAuthenticated()&&<Route path="/home/:tab" element={<HomeLaunch />} /> }
        {isAuthenticated()&&<Route path="/clients" element={<Clients />} />}
        {isAuthenticated()&&<Route path="/clientcredential" element={<Clientcredential />} />}
       {isAuthenticated()&& <Route path="/clientinvoice" element={<Clientinvoice />} />}
        <Route path="/login" element={<Login />} />
        <Route path="/landing" element={<Landing />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<h1>Page not found</h1>} />
      </Route>
    )
  );
  export default router;