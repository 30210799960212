// import service from "service";
import {API} from "../apis"
import service from "./axios_service";

const getProcurements = (type) => {
    let url = `api/v1/admin/procurements/?price_type=${type}`;
    return dispatch => {
        service.get(url)
            .then((res) => {
              if(type=="cost"){
                const procurements = {};
                let items=res.data.procurement??{}
    for (const group in items) {
        procurements[group] = items[group].map((product) => ({
        ...product,
        cost_price: 0
      }));
    }
                dispatch({
                    type: "GET_COST_PRIZE",
                    data: procurements,
                });
            }else {
                const procurements = {};
                let items=res.data.procurement??{}
    for (const group in items) {
        procurements[group] = items[group].map((product) => ({
        ...product,
        selling_price: 0
      }));
    }
                dispatch({
                    type: "GET_SELLING_PRIZE",
                    data: procurements,
                });
            }
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}

const getOrders = (type) => {
    let url = `api/v1/admin/orders/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

        service.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "GET_ORDERS",
                    data: res.data,
                });
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}


const updateOrderStatus = (payload) => {
    let url = `api/v1/admin/orders/${payload["order_id"]}/status`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

        service.put(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
               dispatch(getOrders());
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}


const prepareinvoice = (id) => {
    let url = `api/v1/admin/invoices/${id}/prepare_invoice`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "PREPARED_INVOICE",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const getClients = () => {
    let url = `api/v1/admin/b2bclients/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "GET_CLIENTS",
                    data: res.data,
                });
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const createClient = (payload) => {
    let url = `api/v1/admin/b2bclients/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const updatePrice = (payload) => {
    let url = `api/v1/admin/products/update-base-price`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                dispatch(getProcurements("cost"))
              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const updateSellingPrice = (payload) => {
    let url = `api/v1/admin/orders/set_item_prices`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                dispatch(getProcurements("selling"))
              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const approveInvoice = (id,payload) => {
    let url = `api/v1/admin/invoices/${id}/approve_invoice`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                // dispatch(getProcurements("selling"))
              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}
export {getProcurements,getOrders,updateOrderStatus,prepareinvoice,getClients,createClient,updatePrice,updateSellingPrice,approveInvoice};