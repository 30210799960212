import React, { useEffect } from "react";

import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Cart from "./cart";
// import Invoices from "./invoices";
// import Analytics from "./analytics";
// import Orders from "./orders";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        props.onClick(event, props.value);
      }}
      {...props}
    />
  );
}

 function Navbar(props) {
  const [value, setValue] = React.useState(0);

  // const handleChange = (newValue) => {
  //   console.log("vale", newValue);

  //   setValue(newValue);
  // };
  const handleChange = (newValue) => {
    console.log("vale",newValue);
    props.router.navigate('/home/'+newValue, { replace: true });
    setValue(newValue);
  };
  useEffect(() => {
    console.log("Props",props);
    setValue(props.router.params.tab);
  }, [props]);
  return (
    <div className="w-full">
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                >
                  <div className="container mx-0 px-0">
                    <div className="row mx-0 px-0">
                      <div className="col-lg-12 d-flex align-items-center justify-content-between">
                        <LinkTab
                          label="COST PRICE"
                          className={`nav-button-section ${
                            value === "costPrize" ? "active" : ""
                          }`}
                          // href="/orders"
                          value={"costPrize"}
                          onClick={() => handleChange("costPrize")}
                        />
                        <LinkTab
                          label="SELLING PRICE"
                          className={`nav-button-section ${
                            value === "sellingPrize" ? "active" : ""
                          }`}
                          // href="/cart"
                          value={"sellingPrize"}
                          onClick={() => handleChange("sellingPrize")}
                        />
                        <LinkTab
                          label="ORDERS"
                          className={`nav-button-section ${
                            value === "orders" ? "active" : ""
                          }`}
                          // href="/invoices"
                          value={"orders"}
                          onClick={() => handleChange("orders")}
                        />
                        {/* <LinkTab
                          label="INVOICE"
                          className={`nav-button-section ${
                            value === 3 ? "active" : ""
                          }`}
                          // href="/analytics"
                          value={3}
                          onClick={() => handleChange(3)}
                        /> */}
                      </div>
                    </div>
                  </div>
                </Tabs>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {/* {value ===0? <Orders />:
      value ===1? <Cart />:
      value ===2? <Invoices />:
      <Analytics />} */}
    </div>
  );
}



function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    sellingPrices: state.procurement.sellingPrices??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navbar)
);