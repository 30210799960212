import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SelectBox from "./selectBox"; // Import the SelectBox component
import { updateOrderStatus,prepareinvoice } from "../../services/Actions/client";

function createData(
  order,
  clientname,
  currentstatus,
  date,
  updatestatus,
  prepareinvoice
) {
  return {
    order,
    clientname,
    currentstatus,
    date,
    updatestatus,
    prepareinvoice,
  };
}

const rows = [
  createData(
    "38738725804",
    "Tiffin Shastra",
    "Order placed",
    "28/07/24",
    "Delivered",
    "invoice"
  ),
  createData(
    "47584973444",
    "Crispy Dosa",
    "Order placed",
    "28/07/24",
    "On The Way",
    "invoice"
  ),
  createData(
    "47584973444",
    "Crispy Dosa",
    "Order placed",
    "28/07/24",
    "Dispatched",
    "invoice"
  ),
  createData(
    "47584973444",
    "Crispy Dosa",
    "Order placed",
    "28/07/24",
    "Cancelled",
    "invoice"
  ),
];

function Order(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(props.orders);
  }, [props]);

  const getStatus = (item) => {
    let replaceValue = item.replace("_", " ");
    return replaceValue.charAt(0).toUpperCase() + replaceValue.slice(1);
  };
  const onChange = (e, item) => {
    let payload = {
      order_id: item.order_id,
      status: e,
    };
    props.dispatch(updateOrderStatus(payload));
  };
  const clickInvoice =(i)=>{
    props.dispatch(prepareinvoice(i.order_id)).then((i)=>{
      if(i){
    props.router.navigate("/home/invoices");
      }
    })
  }
  return (
    <div className="w-full">
      <div className="orderstablesection">
        <TableContainer component={Paper} style={{ borderRadius: "20px" }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{ backgroundColor: "#F1F1F1" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell align="center">Client Name</TableCell>
                <TableCell align="center">Current Status</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Update Status</TableCell>
                <TableCell align="center">Prepare Invoice</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="td" scope="row">
                    {row.order_number}
                  </TableCell>
                  <TableCell align="center">{row.client_name}</TableCell>
                  <TableCell align="center">{getStatus(row.status)}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell
                    align="center"
                    className="d-flex justify-content-center"
                  >
                    <SelectBox
                      value={row.status}
                      onChange={(e) => onChange(e, row)}
                    />{" "}
                    {/* Use the SelectBox component */}
                  </TableCell>
                  <TableCell align="center">
                    {<span onClick={()=>clickInvoice(row)} className="prepareinvoicespansection">INVOICE</span>}
                    {/* {row.invoice_status=="preparable"&& <span onClick={()=>clickInvoice(row)} className="prepareinvoicespansection">INVOICE</span>} */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    orders: state.procurement.orders ?? [],
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
