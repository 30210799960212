import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";
const Sidebar = (props) => {
  const [value, setValue] = useState("Dashboard");

  useEffect(() => {
    let path=props.router.location.pathname;
    if(path.toLowerCase().includes("client")){
      setValue("client")
    }else{
      setValue("Dashboard")
    }
   console.log("Path", props.router.location.pathname)
  //  if()
  }, [props]);
  return (
    <div className="w-full sidebar">
      <div onClick={()=> props.router.navigate("/home/costPrize")} className={`siebarmenusections ${value=="Dashboard"&&"active"}`}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7087 2.29279L21.7087 11.2928C22.3387 11.9228 21.8927 12.9998 21.0017 12.9998H20.0017V18.9998C20.0017 19.7954 19.6857 20.5585 19.1231 21.1211C18.5605 21.6837 17.7974 21.9998 17.0017 21.9998H16.0017V14.9998C16.0018 14.2346 15.7094 13.4983 15.1845 12.9415C14.6595 12.3848 13.9416 12.0497 13.1777 12.0048L13.0017 11.9998H11.0017C10.2061 11.9998 9.44304 12.3159 8.88043 12.8785C8.31782 13.4411 8.00175 14.2041 8.00175 14.9998V21.9998H7.00175C6.2061 21.9998 5.44304 21.6837 4.88043 21.1211C4.31782 20.5585 4.00175 19.7954 4.00175 18.9998V12.9998H3.00175C2.11175 12.9998 1.66475 11.9228 2.29475 11.2928L11.2947 2.29279C11.4823 2.10532 11.7366 2 12.0017 2C12.2669 2 12.5212 2.10532 12.7087 2.29279ZM13.0017 13.9998C13.267 13.9998 13.5213 14.1051 13.7089 14.2927C13.8964 14.4802 14.0017 14.7346 14.0017 14.9998V21.9998H10.0017V14.9998C10.0018 14.7549 10.0917 14.5184 10.2545 14.3354C10.4172 14.1524 10.6415 14.0354 10.8847 14.0068L11.0017 13.9998H13.0017Z" fill="#382E2C"/>
        </svg>
        <h3>Dashboard</h3>
      </div>
      <div onClick={()=> props.router.navigate("/clients")} className={`siebarmenusections ${value=="client"&&"active"}`}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 11C6.10312 11 7 10.1031 7 9C7 7.89688 6.10312 7 5 7C3.89688 7 3 7.89688 3 9C3 10.1031 3.89688 11 5 11ZM19 11C20.1031 11 21 10.1031 21 9C21 7.89688 20.1031 7 19 7C17.8969 7 17 7.89688 17 9C17 10.1031 17.8969 11 19 11ZM20 12H18C17.45 12 16.9531 12.2219 16.5906 12.5813C17.85 13.2719 18.7438 14.5187 18.9375 16H21C21.5531 16 22 15.5531 22 15V14C22 12.8969 21.1031 12 20 12ZM12 12C13.9344 12 15.5 10.4344 15.5 8.5C15.5 6.56562 13.9344 5 12 5C10.0656 5 8.5 6.56562 8.5 8.5C8.5 10.4344 10.0656 12 12 12ZM14.4 13H14.1406C13.4906 13.3125 12.7688 13.5 12 13.5C11.2312 13.5 10.5125 13.3125 9.85938 13H9.6C7.6125 13 6 14.6125 6 16.6V17.5C6 18.3281 6.67188 19 7.5 19H16.5C17.3281 19 18 18.3281 18 17.5V16.6C18 14.6125 16.3875 13 14.4 13ZM7.40938 12.5813C7.04688 12.2219 6.55 12 6 12H4C2.89688 12 2 12.8969 2 14V15C2 15.5531 2.44687 16 3 16H5.05938C5.25625 14.5187 6.15 13.2719 7.40938 12.5813Z" fill="#382E2C"/>
        </svg>
        <h3>Clients</h3>
      </div>
      <div className="siebarmenusections">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_320_219)">
            <path d="M11.69 2.0823C11.7844 2.02837 11.8913 2 12 2C12.1087 2 12.2156 2.02837 12.31 2.0823L16.9975 4.76105C17.0932 4.81569 17.1727 4.89465 17.228 4.98991C17.2833 5.08518 17.3125 5.19338 17.3125 5.30355V10.2973L21.685 12.7973C21.7803 12.8517 21.8596 12.9303 21.9149 13.0251C21.9702 13.1199 21.9995 13.2276 22 13.3373V18.6948C22 18.805 21.9708 18.9132 21.9155 19.0084C21.8602 19.1037 21.7807 19.1827 21.685 19.2373L16.9975 21.916C16.9031 21.97 16.7962 21.9983 16.6875 21.9983C16.5788 21.9983 16.4719 21.97 16.3775 21.916L12 19.416L7.6225 21.9173C7.52808 21.9712 7.42123 21.9996 7.3125 21.9996C7.20377 21.9996 7.09692 21.9712 7.0025 21.9173L2.315 19.2385C2.21934 19.1839 2.13982 19.1049 2.08451 19.0097C2.02919 18.9144 2.00004 18.8062 2 18.696V13.3385C2.00004 13.2284 2.02919 13.1202 2.08451 13.0249C2.13982 12.9296 2.21934 12.8507 2.315 12.796L6.6875 10.2973V5.30355C6.68754 5.19338 6.71669 5.08518 6.77201 4.98991C6.82732 4.89465 6.90684 4.81569 7.0025 4.76105L11.69 2.0823ZM7.3125 11.3798L3.885 13.3385L7.3125 15.2973L10.74 13.3385L7.3125 11.3798ZM11.375 14.416L7.9375 16.3798V20.2973L11.375 18.3335V14.416ZM12.625 18.3335L16.0625 20.2973V16.3798L12.625 14.416V18.3335ZM13.26 13.3385L16.6875 15.2973L20.115 13.3385L16.6875 11.3798L13.26 13.3385ZM16.0625 10.2973V6.3798L12.625 8.3448V12.2623L16.0625 10.2973ZM11.375 12.2623V8.3448L7.9375 6.3798V10.2973L11.375 12.2623ZM8.5725 5.30355L12 7.2623L15.4275 5.30355L12 3.3448L8.5725 5.30355ZM20.75 14.416L17.3125 16.3798V20.2973L20.75 18.3335V14.416ZM6.6875 20.2973V16.3798L3.25 14.416V18.3335L6.6875 20.2973Z" fill="#382E2C"/>
            </g>
            <defs>
            <clipPath id="clip0_320_219">
            <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
            </clipPath>
            </defs>
        </svg>
        <h3>Products</h3>
      </div>
      <div className="siebarmenusections">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33333 4C4.44928 4 3.60143 4.35119 2.97631 4.97631C2.35119 5.60143 2 6.44928 2 7.33333V7.66833L12 13.0533L22 7.67V7.33333C22 6.44928 21.6488 5.60143 21.0237 4.97631C20.3986 4.35119 19.5507 4 18.6667 4H5.33333ZM22 9.56167L12.395 14.7333C12.2736 14.7987 12.1379 14.8329 12 14.8329C11.8621 14.8329 11.7264 14.7987 11.605 14.7333L2 9.56167V17.3333C2 18.2174 2.35119 19.0652 2.97631 19.6904C3.60143 20.3155 4.44928 20.6667 5.33333 20.6667H18.6667C19.5507 20.6667 20.3986 20.3155 21.0237 19.6904C21.6488 19.0652 22 18.2174 22 17.3333V9.56167Z" fill="#382E2C"/>
        </svg>
        <h3>Messages</h3>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);

