import { combineReducers } from 'redux'
import productReducer from './product';
import procurement from "./procurement"
// Combine Reducers
const rootReducer = combineReducers({
  procurement: procurement,
    // invoice:invoiceReducer,
    // Add other reducers here if you have more
  });
  
  export default rootReducer;