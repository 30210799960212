import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Sellingprice from "./sellingprice";
import {getProcurements,updateSellingPrice} from "../../services/Actions/client"


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function HomeSellingPrize(props) {
  const [rows, setRows] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(()=>{
    props.dispatch(getProcurements("selling"));
      },[])
      useEffect(()=>{
        setRows(props.sellingPrices)
          },[props])
  const handleSelectAllClick = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
  };
const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
  const handleItemSelection = (newSelected) => {
    setSelected(newSelected);
    setSelectAll(newSelected.length > 0 && newSelected.length === 2); // Adjust based on your total rows
  };
  const onUpdateRows=(key, items)=>{
    // let updatedRows=rows;
    // updatedRows[key]=items;
    let updatedRows = {
      ...rows,
      [key]: items,
    };
    // let updatedRows ={
    //   ...rows,
    //   key:items
    // }
    console.log("updatedRows0,",updatedRows);
    setRows(updatedRows);
  }
  const onUpdatePrice=()=>{
let result=[]
       Object.values(rows)
  .flat()
  .map((item) =>{ 
    if(item.selling_price!=0){
    result.push({
      product_id: item.product_id,
      unit_price: item.selling_price
  })
}
});
  console.log("result",result)
  let payload={
    "prices": result,
    "update_existing": true

}
  props.dispatch(updateSellingPrice(payload));
    }
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="productssection">
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
                <div className="inputseachsection col-4">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={(e)=>setSearch(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(Object.keys(rows).length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  </Stack>
                </div>
              </div>
              <div className="d-flex align-items-center" style={{ marginLeft: "10px" }}>
                <Checkbox
                  {...label}
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                />
                <h4
                  className="selectalltextsection"
                  onClick={handleSelectAllClick}
                  style={{ cursor: "pointer" }}
                >
                  Select all
                </h4>
              </div>
              {Object.entries(rows).map(([key, value]) => {
  return <Sellingprice
  selectAll={selectAll}
  onUpdateRow={(key,items)=>onUpdateRows(key,items)} 
  handleItemSelection={handleItemSelection} name={key} items={value.filter(product => 
    search?  product.name.toLowerCase().includes(search.toLowerCase()):true
  )} />;
})}
              {/* <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              /> */}
              <div className="col-lg-12 my-5 d-flex justify-content-end">
                <button onClick={onUpdatePrice} className="nav-button-section">Update Price</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    sellingPrices: state.procurement.sellingPrices??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeSellingPrize)
);