import React, { useEffect, useState } from "react";
import Logo from "../assets/farmaze_logo.png";
import Notification from "../assets/icons/notification.svg";
import User from "../assets/icons/user.svg";
import { isAuthenticated } from "../Pages/Router/privateRoute";

import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

const HeaderProps = (props) => {
  const isLogin = isAuthenticated();
  const [value, setValue] = useState("dashboard");

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);
  return (
    <div className="w-full sticky-wrapper-header">
      <div className="header py-2">
        <div className="container px-0">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col">
              <a href="/home">
                <img src={Logo} width={60} alt="Logo" />
              </a>
            </div>
            {isLogin && (
              <div className="col d-flex justify-content-end align-items-center navbar-section">
                <h4>
                  <a
                    className={` ${
                      value === "dashboard" ? "active-option" : ""
                    }`}
                    href="/dashboard/0"
                  >
                    Home
                  </a>
                </h4>
                <h4>
                  <a
                    className={` ${value === "myorders" ? "active-option" : ""}`}
                    href="/MyOrders"
                  >
                    My Orders
                  </a>
                </h4>
                <button className="button-section">My Profile</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainHeaderProps = (props) => {
  const [value, setValue] = useState("dashboard");

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);
  return (
    <div className="w-full sticky-wrapper-main-header">
      <div className="header py-2">
        <div className="container px-0">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-8 d-flex align-items-center">
              <div className="col-4">
                <a href="/home">
                  <img src={Logo} width={60} alt="Logo" />
                </a>
              </div>
              <div className="col-8">
                <div>
                  <div className="inputseachsection" style={{width: "60%"}}>
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-evenly align-items-center helpandreportsection">
              <h4>Help</h4>
              <h4>Report</h4>
              {/* </div>
            <div className="col d-flex justify-content-end align-items-center navbar-section"> */}
              <h4 className="notificationsection">
                <a
                  className={` ${value === "myorders" ? "active-option" : ""}`}
                  href="/MyOrders"
                >
                  <img
                    src={Notification}
                    alt="icon"
                    className="notification headericonsection"
                  ></img>
                </a>
              </h4>
              <h4>
                <a
                  className={` ${value === "myorders" ? "active-option" : ""}`}
                  href="/MyOrders"
                >
                  <img
                    src={User}
                    alt="icon"
                    className="user headericonsection"
                  ></img>
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    // products: state.product.products,
  };
}

const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderProps)
);
const MainHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainHeaderProps)
);
export { Header, MainHeader };
