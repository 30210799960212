import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Orders from "./orders";
import {getOrders} from "../../services/Actions/client"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


function HomeOrders(props) {
  useEffect(()=>{
    props.dispatch(getOrders());
      },[])
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />        
    //   </div>
    //   <Navbar />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="productssection">
              <div className="col-lg-12 mb-3">
                <h3 className="primaryheader">Today’s Orders</h3>
              </div>
              <Orders />
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    orders: state.procurement.orders??[]

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeOrders)
);
