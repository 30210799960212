import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {createClient} from "../../services/Actions/client"

function Clientcredentialdetails(props) {
  const [userInfo, setUserInfo] = useState({
    "username": "",
    "password": ""
});

const [address, setAddress] = useState({
  "address_line_1": "",
  "address_line_2": "",
  "zip_code": "",
  "city": "",
  "country": ""
});
const [clientInfo, setClientInfo] = useState({
  "name": "",
  "phone_number": "",
  "company_name": "",
  "contact_name": "",
  "email": "",
  "gstid": ""
});
const onUpdateField = (e) => {
  const nextFormState = {
    ...userInfo,
    [e.target.name]: e.target.value,
  };
  setUserInfo(nextFormState);
};
const onUpdateAddress = (e) => {
  const nextFormState = {
    ...address,
    [e.target.name]: e.target.value,
  };
  setAddress(nextFormState);
};
const onUpdateClientDetails = (e) => {
  const nextFormState = {
    ...clientInfo,
    [e.target.name]: e.target.value,
  };
  setClientInfo(nextFormState);
};
const addClient=()=>{
  let payload= {
    "client": {
      ...clientInfo,
        "billing_address":address,
        "shipping_address":address
    },
    "user": userInfo
}
props.dispatch(createClient(payload)).then(()=>{
  props.router.navigate(-1)
});

}
  return (
    <div className="w-full">
      <div className="clientcredentialdetailstablesection d-flex justify-content-center">
        <div className="col-lg-10 d-flex align-items-center flex-column">
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <h3 class="primaryheader">CLIENT DETAILS</h3>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Client Name</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="name" fullWidth id="fullWidth" value={clientInfo.name} onChange={onUpdateClientDetails} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Company Name</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="company_name" fullWidth id="fullWidth" value={clientInfo.company_name} onChange={onUpdateClientDetails}  />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">E-Mail Id</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="email" fullWidth id="fullWidth" value={clientInfo.email} onChange={onUpdateClientDetails} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Phone Number</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="phone_number" fullWidth id="fullWidth" value={clientInfo.phone_number} onChange={onUpdateClientDetails}/>
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Contact Name</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="contact_name" fullWidth id="fullWidth" value={clientInfo.contact_name} onChange={onUpdateClientDetails} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">GST ID</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="gstid" fullWidth id="fullWidth" value={clientInfo.gstid} onChange={onUpdateClientDetails} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Address 1</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="address_line_1" fullWidth id="fullWidth" value={address.address_line_1} onChange={onUpdateAddress} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Address 2</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="address_line_2" fullWidth id="fullWidth" value={address.address_line_2} onChange={onUpdateAddress} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">City</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="city" fullWidth id="fullWidth" value={address.city} onChange={onUpdateAddress} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">Country</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="country" fullWidth id="fullWidth" value={address.country} onChange={onUpdateAddress}/>
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">ZIP CODE</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="zip_code" fullWidth id="fullWidth" value={address.zip_code} onChange={onUpdateAddress} />
              </Box>
            </div>
          </div>
          <div
            className="col-lg-12 d-flex align-items-center clientcredentialinputsection"
            style={{ marginTop: "30px" }}
          >
            <h3 class="primaryheader">CREDENTIALS SET UP</h3>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">USERNAME</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="username" fullWidth id="fullWidth" value={userInfo.username} onChange={onUpdateField} />
              </Box>
            </div>
          </div>
          <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
            <div className="col-lg-5">PASSWORD</div>
            <div className="col-lg-7">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "13px",
                }}
              >
                <TextField name="password" fullWidth id="fullWidth" value={userInfo.password} onChange={onUpdateField} />
              </Box>
            </div>
          </div>
          <div class="col-lg-12 my-5 d-flex justify-content-center">
            <button onClick={addClient} class="nav-button-section">CREATE</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientcredentialdetails)
);
