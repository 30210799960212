const initialState = {
    products: [],
    myOrders:[]
  };
  
  const productReducer = (state = initialState, action) => {
    console.log("action",action)
    switch (action.type) {
      case "GET_PRODUCTS":
        return Object.assign({}, state, {
          products: action.data,
        });
        break;
        case "GET_MY_ORDERS":
          return Object.assign({}, state, {
            myOrders: action.data,
          });
          break;
      case "DECREMENT":
        return { ...state, counter: state.counter - 1 };
      default:
        return state;
    }
  };
  
  export default productReducer;