import React from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

function Dashboard(props) {
    
  return (
    <div className="w-full">
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12">
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
