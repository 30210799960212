import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SelectBox from "./selectBox";
import DeleteIcon from "../../assets/icons/delete.svg";
import EditIcon from "../../assets/icons/edit.svg";

function createData(invoice, invoicedate, status, amount) {
  return { invoice, invoicedate, status, amount };
}

// const rows = [
//   createData("38738725804", "28/07/2024", "Delivered", "$890"),
//   createData("38738725804", "28/07/2024", "On The Way", "$5486"),
//   createData("38738725804", "28/07/2024", "Delivered", "$890"),
//   createData("38738725804", "28/07/2024", "Delivered", "$890")
// ];

function Invoicetable(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let i=props.prepareInvoices;
    setRows(i.order_items);
  }, [props]);
  return (
    <div className="w-full">
      <div className="clientinvoicedetailtablesection">
        <TableContainer component={Paper} style={{ borderRadius: "20px", boxShadow: "none" }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{ backgroundColor: "#F1F1F1" }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Invoice</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <div className="invoiceandinvoicedatesection">
                      <h3>{row.invoice}</h3>
                      <h5>{row.invoicedate}</h5>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                                      >
                                        <div className="d-flex justify-content-center">
                    <SelectBox value={row.updatestatus} />
                    </div>
                  </TableCell>
                  <TableCell align="center" className="invoiceclientamountsection">{row.amount}</TableCell>

                  <TableCell align="center">
                    <div className="deleteandediticon d-flex justify-content-evenly">
                      <img
                        src={DeleteIcon}
                        alt="deleteicon"
                        className="delete-icon"
                      ></img>
                      <img
                        src={EditIcon}
                        alt="editicon"
                        className="edit-icon"
                      ></img>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Invoicetable)
);
