const initialState = {
  costPrices: {},
  sellingPrices:{},
  orders:[],
  prepareInvoices:{},
  clients:[],
  };
  
  const procurementReducer = (state = initialState, action) => {
    console.log("action",action)
    switch (action.type) {
      case "GET_COST_PRIZE":
        return Object.assign({}, state, {
          costPrices: action.data,
        });
        break;
        case "GET_SELLING_PRIZE":
          return Object.assign({}, state, {
            sellingPrices: action.data,
          });
          break;
          case "GET_ORDERS":
            return Object.assign({}, state, {
              orders: action.data,
            });
            break;
            case "PREPARED_INVOICE":
            return Object.assign({}, state, {
              prepareInvoices: action.data,
            });
            break;
            case "GET_CLIENTS":
            return Object.assign({}, state, {
              clients: action.data,
            });
            break;
      case "DECREMENT":
        return { ...state, counter: state.counter - 1 };
      default:
        return state;
    }
  };
  
  export default procurementReducer;