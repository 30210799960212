import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Costprice from "./costprice";
import {getProcurements, updatePrice} from "../../services/Actions/client"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


function HomeCostPrize(props) {
  const [rows, setRows] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(()=>{
    props.dispatch(getProcurements("cost"));
    // props.dispatch(getOrderIds());
      },[])

      useEffect(()=>{
        setRows(props.costPrices)
          },[props])
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const onUpdateRows=(key, items)=>{
        // let updatedRows=rows;
        // updatedRows[key]=items;
        let updatedRows = {
          ...rows,
          [key]: items,
        };
        // let updatedRows ={
        //   ...rows,
        //   key:items
        // }
        console.log("updatedRows0,",updatedRows);
        setRows(updatedRows);
      }

      const onUpdatePrice=()=>{
      // "prices": result,
let result=[]
         Object.values(rows)
    .flat()
    .map((item) =>{ 
      if(item.cost_price!=0){
      result.push({
        product_id: item.product_id,
        unit_price: item.cost_price
    })
  }
  });
    console.log("result",result)
    let payload={
      "prices": result,
      "update_existing": true

  }
    props.dispatch(updatePrice(payload));
      }
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />        
    //   </div>
    //   <Navbar />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="productssection">
              <div className="col-lg-12 mb-3">
                <h3 className="primaryheader">COST PRICE</h3>
              </div>
              <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
                <div className="inputseachsection col-4">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search Products..."
                    aria-label="Search"
                    onChange={(e)=>setSearch(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(Object.keys(rows).length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                  </Stack>
                </div>
              </div>
              {Object.entries(rows).map(([key, value]) => {
  return <Costprice onUpdateRow={(key,items)=>onUpdateRows(key,items)} value={search} name={key} items={value.filter(product => 
    search?  product.name.toLowerCase().includes(search.toLowerCase()):true
  )} />;
})}
              {/* <Costprice name={} />
              <Costprice />
              <Costprice />
              <Costprice /> */}
              <div className="col-lg-12 my-5 d-flex justify-content-center">
                  <button onClick={onUpdatePrice} className="nav-button-section">
                    Update Price
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    costPrices: state.procurement.costPrices??{}
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeCostPrize)
);
