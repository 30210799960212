import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(sku, productname, quantity, unit, currentprice, total) {
  return { sku, productname, quantity, unit, currentprice, total };
}

const initialRows = [
  createData("957089", "Palak", 10, "Bunch", 8, 80),
  createData("957089", "Palak", 10, "Bunch", 8, 80),
];

function Sellingprice(props) {
  let { selectAll, handleItemSelection }= props;
  const [rows, setRows] = useState([]);
  const [name, setName] = useState("");

  const [selected, setSelected] = useState(
    selectAll ? rows.map((_, index) => index) : []
  );

  
  useEffect(()=>{
    let {name, items}= props;
   setName(name);
   setRows(items);
      },[props])
      
  useEffect(() => {
    if (selectAll) {
      setSelected(rows.map((_, index) => index));
    } else {
      setSelected([]);
    }
  }, [selectAll]);

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    handleItemSelection(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const handleInputChange = (e, rowIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, selling_price: parseFloat(e.target.value) || 0 };
      }
      return row;
    });
    setRows(updatedRows);
    console.log("check1",updatedRows)

    if(props.onUpdateRow){
      console.log("check2")
      props.onUpdateRow(name, updatedRows)
    }
  };

  
   
  const getTotal = (row) => {
    let price = row.selling_price!=0 ? row.selling_price * row.quantity : row.unit_price * row.quantity;
    return price;
  };
  return (
    <div className="w-full">
      <div className="sellingpricetablesection">
        <div className="d-flex" style={{marginLeft: "10px"}}>
          <Checkbox {...label} />
          <div className="tableheader">
            <h3>{name}</h3>
          </div>
        </div>
        <TableContainer
          component={Paper}
          style={{ borderRadius: "0px", boxShadow: "none" }}
        >
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            className="table table-bordered my-0 sellingpricetable"
            style={{ border: "1px solid #000" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    backgroundColor: "transparent !important",
                    color: "transparent !important",
                    width: "20px",
                    border: "none !important"
                  }}
                />
                <StyledTableCell align="center">SKU</StyledTableCell>
                <StyledTableCell align="center">Product Name</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Unit</StyledTableCell>
                <StyledTableCell align="center">Cost Price</StyledTableCell>
                <StyledTableCell align="center">Selling Price</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" align="left">
                    <Checkbox
                      checked={isSelected(index)}
                      onChange={() => handleCheckboxClick(index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.sku}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.quantity}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.unit}</StyledTableCell>
                  <StyledTableCell align="center">{row.unit_price}</StyledTableCell>

                  <StyledTableCell
                    align="center"
                    className="currentpricedivsection"
                  >
                    <span>
                    <input
                          type="number"
                          className="quantityinputsection"
                          // style={{ backgroundColor: "#fff !important" }}
                          value={row.selling_price || ""}
                          onChange={(e) => handleInputChange(e, index)}
                          min="1"
                        />
                        </span>
                    {/* <div
                      className="currentpricedatasection"
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      {row.unit_price}
                    </div> */}
                  </StyledTableCell>
                  <StyledTableCell align="center">{getTotal(row)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sellingprice)
);
