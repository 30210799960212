import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Costprice(props) {
  const [rows, setRows] = useState([]);
  const [name, setName] = useState("");

  useEffect(() => {
    let { name, items } = props;
    setName(name);
    setRows(items);
  }, [props]);

  const handleInputChange = (e, rowIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, cost_price: parseFloat(e.target.value) || 0 };
      }
      return row;
    });
    setRows(updatedRows);
    console.log("check1")

    if(props.onUpdateRow){
      console.log("check2")
      props.onUpdateRow(name, updatedRows)
    }
  };
  
  const getTotal = (row) => {
    let price = row.cost_price!=0 ? row.cost_price * row.quantity : row.unit_price * row.quantity;
    return price;
  };

  return (
    <div className="w-full">
      <div className="costpricetablesection">
        <div className="tableheader">
          <h3>{name}</h3>
        </div>
        <TableContainer
          component={Paper}
          style={{ borderRadius: "0px", boxShadow: "none" }}
        >
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            className="table table-bordered my-0"
            style={{ border: "1px solid #000" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>SKU</StyledTableCell>
                <StyledTableCell align="center">Product Name</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Unit</StyledTableCell>
                <StyledTableCell align="center">Previous Price</StyledTableCell>
                <StyledTableCell align="center">Cost Price</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.product_id}>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.sku}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                  <StyledTableCell align="center">{row.unit}</StyledTableCell>
                  <StyledTableCell align="center">{row.unit_price}</StyledTableCell>
                  <StyledTableCell align="center" className="currentpricedivsection">
                    <div
                      className="currentpricedatasection"
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <span>
                        <input
                          type="number"
                          className="quantityinputsection"
                          value={row.cost_price || ""}
                          onChange={(e) => handleInputChange(e, index)}
                          min="1"
                        />
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">{getTotal(row)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Costprice));
