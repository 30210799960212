import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Clientdetails from "./clientdetails";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {getClients} from "../../services/Actions/client"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Clients(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
  props.dispatch(getClients())
  console.log("pros", props)
  }, []);
  useEffect(() => {
    let i=props.clients;

    setRows(i);
  }, [props]);



  return (
    <div className="w-full">
      <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div class="col-12 d-flex justify-content-end align-items-center" style={{marginBottom: "20px"}}>
              <div className="col-6">
                <h3 class="primaryheader">CLIENT</h3>
              </div>
              <div className="col-6 text-end">
                <button onClick={()=> props.router.navigate("/clientcredential")} className="nav-button-section active">
                  ADD NEW CLIENTS
                </button>
              </div>
            </div>
            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              <div className="inputseachsection col-4">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                />
              </div>
              <div className="d-flex justify-content-end">
                <Stack spacing={2}>
                  <Pagination count={10} showFirstButton showLastButton />
                </Stack>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "10px" }}
            >
              <Checkbox {...label} />
              <h4
                className="selectalltextsection m-0"
                style={{ cursor: "pointer" }}
              >
                Select all
              </h4>
            </div>
            <div className="productssection">
              {/* <Clientdetails /> */}
              <div className="clientdetailstablesection">
        <TableContainer component={Paper} style={{ borderRadius: "20px" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                <TableCell align="left" width={50}>
                  #
                </TableCell>
                <TableCell align="center">Product</TableCell>
                <TableCell align="center">Area</TableCell>
                <TableCell align="center">Contact</TableCell>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{index}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.shipping_address?.address_line_1??""}, {row.shipping_address?.address_line_2??""}</TableCell>
                    <TableCell align="center">
                      <a href={`mailto:${row.contact}`}>{row.email}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    clients: state.procurement.clients??[]

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clients)
);
