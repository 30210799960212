import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Invoice from "./invoice";
import Invoicedetails from "./invoicedetails";
import {approveInvoice} from "../../services/Actions/client"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Invoices(props) {
  const [rows, setRows] = useState([]);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let i=props.prepareInvoices;
    // let totalPrize=0;
    setRows(i.order_items)
    // i.order_items&&i.order_items?.map((e)=>{
    //  totalPrize = totalPrize+(e.quantity* e.unit_price);
    // })
    // setTotal(totalPrize);
  }, [props]);

  const onUpdateRows=(items)=>{
    console.log("items cancel",items)
  
    setRows(items);
  }
  const onApprove=()=>{
    console.log("cadsdasd cancel",rows)
    const data = rows.map(item => ({
      id: item.item_id,
      order_id: item.order_id,
      product_id: item.item_id,  // Assuming product_id is same as item_id
      quantity: item.quantity,
      unit_price: item.unit_price
  }));
  let payload ={
    "items": data
}
    props.dispatch(approveInvoice(props.prepareInvoices.order.order_id,payload))
  }

  const getTotal=()=>{
    let totalPrize=0;
    // setRows(i.order_items)
    rows&&rows?.map((e)=>{
     totalPrize = totalPrize+(e.quantity* e.unit_price);
    })
    return totalPrize;
  }
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="productssection">
              <Invoice />
              <Invoicedetails items={rows} onUpdateRow={(items)=>onUpdateRows(items)} />
              <div className="col-12 my-5 d-flex justify-content-end align-items-center">
                <div className="col-6 d-flex justify-content-end align-items-center grandtotalsection">
                  <div className="col-6 text-end"><h3>Grand Total</h3></div>
                  <div className="col-6 text-end"><h3>{getTotal()} INR</h3></div>
                </div>
              </div>
              <div className="col-lg-12 my-5 d-flex justify-content-end">
                <button onClick={onApprove} className="nav-button-section">APPROVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices??{}
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Invoices)
);
