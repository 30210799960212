import React from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import BackIcon from "../../assets/icons/back-icon.svg";
import EditIcon from "../../assets/icons/edit.svg";
import Invoicetable from "./invoicetable";

function Clientinvoicedetails(props) {
  return (
    <div className="w-full">
      <div className="clientinvoicedetailssection d-flex justify-content-center">
        <div className="col-lg-12 d-flex">
          <div
            className="col-lg-4"
            style={{ borderRight: "2px solid #8C7E7E" }}
          >
            <div
              class="col-12 d-flex justify-content-start align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <img src={BackIcon} alt="icon" className="gobackicon"></img>
              <h6>Go Back</h6>
            </div>
            <div className="clientprofilepicturesection"></div>
            <div className="clientprofiledetailsection">
              <h3>Name of the Restaurant</h3>
              <h3 className="accountnumbersection">Account Number</h3>
              <h5>Email: restaurantname@gmail.com</h5>
              <h5>Phone : +91 90837 83753</h5>
            </div>
            <div className="clientaddressdetailsection">
              <h3>Address</h3>
              <h5>6 forestlaneway, North York</h5>
            </div>
            <div className="changepasswordsection d-flex align-items-center mt-5">
              <h3 className="m-0">Change Password</h3>
              <img src={EditIcon} alt="icon" className="editicon"></img>
            </div>
            <div className="col-lg-12 text-center editdetailsbuttonsection">
              <button class="nav-button-section active">EDIT DETAILS</button>
            </div>
          </div>
          <div className="col-lg-8" style={{padding: "0 20px"}}>
            <div className="col-lg-12 d-flex align-items-center justify-content-between">
              <div className="col-lg-4 totalinvoicesection invoicesdashboardsection">
                <h3>150</h3>
                <h3>Invoices Total</h3>
              </div>
              <div className="col-lg-4 paidinvoicessection invoicesdashboardsection paidinvoicesandpendinginvoicessection">
                <h3 style={{ color: "#007B5F" }}>$7,029,200</h3>
                <h3>Paid Invoices</h3>
              </div>
              <div className="col-lg-4 pendinginvoicessection invoicesdashboardsection paidinvoicesandpendinginvoicessection">
                <h3 style={{ color: "#F79250" }}>$7,029</h3>
                <h3>Pending Invoices</h3>
              </div>
            </div>
            <div className="col-lg-12" style={{marginTop: "30px"}}>
              <div className="inputseachsection col-8">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search Products..."
                  aria-label="Search"
                />
              </div>
            </div>
            <Invoicetable />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientinvoicedetails)
);
