import React from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(serialnumber, product, area, contact) {
  return { serialnumber, product, area, contact };
}

const rows = [
  createData(1, "Crispy Dosa", "Anna Nagar", "crispydosa@gmail.com"),
  createData(2, "Restaurant Name", "T Nagar", ""),
  createData(3, "Restaurant Name", "Nugambakkam", ""),
  createData(4, "Restaurant Name", "Tambaram", ""),
  createData(5, "Restaurant Name", "Anna Nagar", ""),
];

function Clientdetails(props) {
  return (
    <div className="w-full">
      <div className="clientdetailstablesection">
        <TableContainer component={Paper} style={{ borderRadius: "20px" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                <TableCell align="left" width={50}>
                  #
                </TableCell>
                <TableCell align="center">Product</TableCell>
                <TableCell align="center">Area</TableCell>
                <TableCell align="center">Contact</TableCell>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.serialnumber}</TableCell>
                    <TableCell align="center">{row.product}</TableCell>
                    <TableCell align="center">{row.area}</TableCell>
                    <TableCell align="center">
                      <a href={`mailto:${row.contact}`}>{row.contact}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientdetails)
);
